import React, {useState, useContext, createContext} from 'react'

const SuperCacheContext = createContext({})

export const SuperCacheProvider = ({ children, initial={} }) => {
  const [cache, setCache] = useState(initial)
  const updateMe = (me) => setCache(_state => ({..._state, me}))
  
  return (
    <SuperCacheContext.Provider
      value={{
        ...cache,
        updateMe: updateMe,
      }}>
      {children}
    </SuperCacheContext.Provider>
  )
}

export default () => useContext(SuperCacheContext)
